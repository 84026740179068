import t, { Languages } from "../data/translation/main";
import "../css/Services.css";

function Services({ language }: { language: keyof Languages }): JSX.Element {
    return (
        <section className="App-container" id="services">
            <div className="services-inner-container no-padding-bottom">
                <span>
                    {t.servicesCompanyName[language]}&nbsp;
                    <span className="services-additional-text">{t.servicesCompanyDescription[language]}</span>
                </span>
            </div>
            <div className="services-inner-container no-padding-top">
                <span className="list-headline">
                    {t.servicesListHeadline[language]}
                    <ul>
                        <li>{t.servicesListItem1[language]}</li>
                        <li>{t.servicesListItem2[language]}</li>
                        <li>{t.servicesListItem3[language]}</li>
                        <li>{t.servicesListItem4[language]}</li>
                        <li>{t.servicesListItem5[language]}</li>
                        <li>{t.servicesListItem6[language]}</li>
                    </ul>
                </span>
            </div>
        </section>
    );
}

export default Services;
