import { Dispatch, SetStateAction } from "react";
import { Languages } from "../data/translation/main";
import "../css/LanguageSwitch.css";

function LanguageSwitch({ language, setLanguage }: { language: string; setLanguage: Dispatch<SetStateAction<keyof Languages>> }): JSX.Element {
    const languagesAvailable = ["de", "en"];
    return (
        <div className="lang-switch">
            <p>
                {languagesAvailable.map((lang, index) => (
                    <span key={index}>
                        {index !== 0 && " | "}
                        <a
                            className={language === lang ? "language-link-active" : "language-link"}
                            href="#top"
                            onClick={(e) => {
                                e.preventDefault();
                                setLanguage(lang as keyof Languages);
                            }}
                        >
                            {lang}
                        </a>
                    </span>
                ))}
            </p>
        </div>
    );
}
export default LanguageSwitch;
