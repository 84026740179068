import { useState } from "react";
import t, { Languages } from "../data/translation/main";
import { sectionList } from "../util/sectionList";
import smoothScroll from "../util/smoothScroll";
import Services from "../components/Services";
import Products from "../components/Products";
import Imprint from "../components/Imprint";
import LanguageSwitch from "../components/LanguageSwitch";
import TopButton from "../components/TopButton";

function Main(): JSX.Element {
    const browserLang = navigator.language.substring(0, 2);
    const [lang, setLang] = useState<keyof Languages>(browserLang === "de" ? browserLang : "en");

    return (
        <>
            <div className="App">
                <header className="App-container App-header" id="top">
                    <img src={require("../assets/daidalos_logo_large.png")} className="App-logo" alt="logo" />

                    <div className="category-link-container">
                        {sectionList.map((item, index) => (
                            <a
                                className="category-link"
                                key={index}
                                onClick={(e) => smoothScroll(e, item.linkId)}
                                style={{ animationDelay: index / 1.5 + 1 + "s" }}
                                href="#top"
                            >
                                {t[item.title][lang]}
                            </a>
                        ))}
                    </div>
                </header>
                <Services language={lang} />
                <Products language={lang} />
                <Imprint language={lang} />
            </div>
            <LanguageSwitch language={lang} setLanguage={setLang} />
            <TopButton />
        </>
    );
}

export default Main;
