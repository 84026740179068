import "../css/TopButton.css";
import upArrow from "../assets/up-arrow.svg";
import { useEffect, useState } from "react";

function TopButton(): JSX.Element {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        });
    }, []);

    return (
        <div className="top-button-container">
            <div className={isVisible ? "insurance-logo fade-in" : "insurance-logo"} id="insurance-logo">
                {isVisible && (
                    <a
                        rel="noreferrer"
                        href="https://www.exali.de/siegel/daidalos-Projektservice-AG_1254"
                        target="_blank"
                        title="Weitere Informationen zur IT-Haftpflicht von daidalos Projektservice AG"
                    >
                        <img src={require("../assets/insurance_logo.png")} alt="Weitere Informationen zur IT-Haftpflicht von daidalos Projektservice AG" />
                    </a>
                )}
            </div>
            <div
                className={isVisible ? "top-button fade-in" : "top-button"}
                id="top-button"
                onClick={isVisible ? () => window.scrollTo({ top: 0, behavior: "smooth" }) : () => {}}
            >
                <img src={upArrow} alt="up arrow" />
            </div>
        </div>
    );
}

export default TopButton;
