import t, { Languages } from "../data/translation/main";
import "../css/Products.css";

function Products({ language }: { language: keyof Languages }): JSX.Element {
    return (
        <section className="App-container" id="products">
            <div className="products-inner-container">
                <div className="products-headline">
                    <span className="products-headline-text">Space Opera</span>
                </div>
                <div className="products-description">
                    <p className="products-additional-text">{t.descriptionSpaceOpera[language]}</p>
                </div>
                <div className="products-storelink">
                    <a
                        href="https://apps.apple.com/us/app/space-opera-an-oldschool-rpg/id6478779929?itsct=apps_box_badge&amp;itscg=30200"
                        className="apple-link-button"
                    >
                        <img
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1716336000"
                            alt="Download on the App Store"
                            className="apple-link-image"
                        />
                    </a>
                    <br></br>
                    <a href="https://play.google.com/store/apps/details?id=com.daidalos.spaceopera&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img
                            className="google-link-button"
                            alt="Get it on Google Play"
                            src={`https://play.google.com/intl/en_us/badges/static/images/badges/${language}_badge_web_generic.png`}
                        />
                    </a>
                    <p className="products-small-text">Google Play and the Google Play logo are trademarks of Google LLC.</p>
                </div>
            </div>
        </section>
    );
}

export default Products;
