import { Translation } from "../data/translation/main";

interface SectionObject {
    title: keyof Translation;
    linkId: string;
}

export const sectionList: SectionObject[] = [
    { title: "ServicesTitle", linkId: "services" },
    { title: "ProductsTitle", linkId: "products" },
    { title: "LegalTitle", linkId: "imprint" },
];
