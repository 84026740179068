export interface Translation {
    [prop: string]: Languages;
}

export interface Languages {
    de: string;
    en: string;
}

const t: Translation = {
    ServicesTitle: { de: "Leistungen", en: "Services" },
    ProductsTitle: { de: "Produkte", en: "Products" },
    LegalTitle: { de: "Impressum", en: "Imprint" },
    servicesCompanyName: {
        de: "Die daidalos Projektservice AG",
        en: "The daidalos Projektservice AG",
    },
    servicesCompanyDescription: {
        de: "entwirft und entwickelt Mobile- und Web-Apps für Endbenutzer und Unternehmen. Dabei greifen wir auf über 10 Jahre Branchenerfahrung, insbesondere in der Planung, dem Management und der Umsetzung von Softwareprojekten mit Einzelvolumina von bis zu 2,5 Mio. Euro zurück. Neben der Entwicklung eigener Produkte stellt die daidalos AG ihr Know-How gerne für die Umsetzung Ihrer Projekte zur Verfügung.",
        en: "designs and develops mobile and web applications for end-users and companies. We are looking back to more than 10 years of experience in the industry, especially planning, management and implementation of software pojects with budget volumes up to 2.5 Mio. Euro. Besides the development of genuine products, the daidalos AG gladly provides its experience for the implementation of your projects and products.",
    },
    servicesListHeadline: {
        de: "Unsere Leistungen:",
        en: "Our Services:",
    },
    servicesListItem1: {
        de: "Software-Projektmanagament nach PRINCE2®",
        en: "Software Project Management according to PRNCE2®",
    },
    servicesListItem2: {
        de: "Operatives Projektmanagement nach SAFe®-SCRUM / SCRUM",
        en: "Operational Project Management according to SAFe®-SCRUM / SCRUM",
    },
    servicesListItem3: {
        de: "Hybrid-App-Entwicklung via React-Native für Android / iOS / WatchOS / tvOS und mehr",
        en: "Hybrid-App development with React-Native for Android / iOS / WatchOS / tvOS and more",
    },
    servicesListItem4: {
        de: "Webapp- und SPA-Entwicklung",
        en: "Webapp and SPA development",
    },
    servicesListItem5: {
        de: "Integration von Cloud-Services, wie Google Firebase, Amazon Web Services (AWS) und mehr",
        en: "Cloud Service integration, for example Google Firebase or Amazon Web Services (AWS)",
    },
    servicesListItem6: {
        de: "Spiel-Design, inklusive Monetarisierung",
        en: "Game Design, including monetization",
    },
    descriptionSpaceOpera: {
        de: "Ein klassisches Weltraum-Rollenspiel. Erkunde ein fremdes Universum, errichte eine Basis und schicke deine Flotte auf Erkundung. Aber sei auf der Hut vor den Piraten!",
        en: "An Oldschool Space Role Playing Game. Discover an alien universe, build a base and let your fleet explore. But beware of the space pirates!",
    },
    imprintCommercialRegister: {
        de: "Handelsregisternr.",
        en: "Commercial Register No.",
    },
    imprintSalesTaxId: {
        de: "Umsatzsteuer-ID",
        en: "Sales Tax ID",
    },
    imprintPhone: {
        de: "Telefon",
        en: "Phone",
    },
    imprintCountry: {
        de: "Deutschland",
        en: "Germany",
    },
    imprintBoard: {
        de: "Vertretungsberechtigter Vorstand:",
        en: "Authorized Board Representative:",
    },
    imprintSupervisory: {
        de: "Aufsichtsratsvorsitzende:",
        en: "Chairwoman of the Supervisory Board:",
    },
    imprintEditor: {
        de: "Redaktionell verantwortlich:",
        en: "Editorially responsible:",
    },
    imprintEUCommission: {
        de: "Link auf die Plattform der EU-Kommission zur Online-Streitbeilegung:",
        en: "Link to the EU Commission's online dispute resolution platform:",
    },
    imprintDispute: {
        de: "Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und grundsätzlich nicht bereit.",
        en: "We are not obligated to participate in a dispute resolution procedure before a consumer arbitration board and are generally not willing to do so.",
    },
};

export default t;
