import t, { Languages } from "../data/translation/main";
import "../css/Imprint.css";

function Imprint({ language }: { language: keyof Languages }): JSX.Element {
    return (
        <section className="App-container" id="imprint">
            <div>
                daidalos Projektservice AG <br />
                <p className="imprint-additional-text">
                    Esperantostr. 26
                    <br />
                    55411 Bingen am Rhein
                    <br />
                    {t.imprintCountry[language]}
                </p>
                <p className="imprint-small-text">
                    {t.imprintBoard[language]}
                    <br />
                    {" Conrad Neumann"}
                    <br />
                    {t.imprintSupervisory[language]}
                    <br />
                    {" Cornelia Huth-Neumann"}
                </p>
                <p className="imprint-additional-text">
                    {t.imprintPhone[language]}: <a href="tel: +491792619455">+49 (0) 179 2619455</a>
                    <br />
                    E-Mail:&nbsp;
                    <a href="mailto: info@daidalos-ag.de">info@daidalos-ag.de</a>
                </p>
                <p className="imprint-small-text">
                    {t.imprintCommercialRegister[language]}
                    <br />
                    HRB 52227 <br />
                    <br />
                    {t.imprintSalesTaxId[language]}:
                    <br />
                    DE214104528
                </p>
                <p className="imprint-small-text">
                    {t.imprintEditor[language]}
                    {" Conrad Neumann"}
                </p>
                <p className="imprint-small-text">
                    {t.imprintEUCommission[language]}{" "}
                    <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noreferrer">
                        https://ec.europa.eu/consumers/odr
                    </a>
                    <br />
                    <br />
                    {t.imprintDispute[language]}
                </p>
            </div>
        </section>
    );
}

export default Imprint;
